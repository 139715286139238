import React from 'react';
import { BsTwitter } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs'
import { BsGithub } from 'react-icons/bs'

// Components
import { About } from './personal/About';
import { Projects } from './Projects';
import { Habilidades } from './personal/Habilidades';
import NavbarResponsive from './personal/NavbarResponsive';
// Assets
import resume from "../assets/resume.pdf";

export const Main = () => {
    return (
        <>
        {/* header */}
        <NavbarResponsive />
        <div className="main-container">
            <header className="header">
                <h1 className="links">PortFolio</h1>
                <nav className="navbar">
                    <ul className="navbar-link">
                        <li className="navbar-links">
                            <a href="#about" className="links-hijos">About</a>
                        </li>
                        <li className="navbar-links">
                            <a href="#habilities" className="links-hijos">Abilities</a>
                        </li>
                        <li className="navbar-links">
                            <a href="#projects" className="links-hijos">Projects</a>
                        </li>
                    </ul>
                </nav>
            </header>
                
            <div className="second__container">
                <h4>¡HELLO!</h4>
                <h1>
                    I'm Elvin Garcia
                </h1>
                
                <div className="list-ability">
                    <span className="span-list">
                        <a href="https://twitter.com/Elvis__13" target="_blank" rel="noreferrer">
                            <BsTwitter className='_redes-icons twitter' />
                        </a>
                    </span>
                    <span className="span-list">
                        <a href="https://github.com/elvis1327" target="_blank" rel="noreferrer">
                            <BsGithub className='_redes-icons github' />
                        </a>
                    </span>
                    <span className="span-list">
                        <a href="https://www.linkedin.com/in/elvin-garcia-valdez-459721210" target="_blank" rel="noreferrer">
                            <BsLinkedin className='_redes-icons linkedin' />
                        </a>
                    </span>
                </div>
                {/* Download Resume Button */}
                <button className="boton-about">
                    <a href={resume} download>DOWNLOAD CV</a>
                </button>
            </div>
        </div>
        
        {/* ABOUT */}
        <section className="all__about-me" id="about">
            <About />
        </section>

        {/* Projects */}
        <section className="all__habilities" id="habilities">
            <Habilidades />
        </section>

        {/* Projects */}
        <section className="projects__main" id="projects">
            <Projects />
        </section>

        </>
    )
}


