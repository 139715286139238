import React from 'react';
import { Main } from './components/Main';


export const App = () => {
    return (
        <>
        <Main />
        </>
    )
}

