const initialValues = {
    front_end: [
        {
            lenguaje: 'HTML', 
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1625312760/portfolio-icons/html_rg4dzn.svg'
        },
        {
            lenguaje: 'CSS', 
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1625312760/portfolio-icons/css_jdjuud.svg'
        },
        {
            lenguaje: 'JAVASCRIPT', 
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1625312760/portfolio-icons/js_fxncli.svg'
        },
        // {
        //     lenguaje: 'GIT', 
        //     img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1628543301/portfolio-icons/git-icon_wlsx98.png'
        // },
        {
            lenguaje: 'TYPESCRIPT', 
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1625386347/portfolio-icons/typescript_ddtnkv.png'
        },
        {
            lenguaje: 'REACT', 
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1625312760/portfolio-icons/react_jjoosp.svg'
        },
        {
            lenguaje: 'REDUX', 
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1625386341/portfolio-icons/redux_d6gur0.png'
        }
    ],
    back_end: [
        {
            lenguaje: 'NODEJS',
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1625312760/portfolio-icons/node_monaux.svg'
        },
        {
            lenguaje: 'EXPRESS JS',
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1625312760/portfolio-icons/express_hukgo9.png'
        },
        {
            lenguaje: 'MONGODB',
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1625313768/portfolio-icons/mongo_ub5n4i.png'
        },
        {
            lenguaje: 'GIT',
            img: 'https://res.cloudinary.com/dtp3gqean/image/upload/v1628543301/portfolio-icons/git-icon_wlsx98.png'
        },
    ]
};

export default initialValues;