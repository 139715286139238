import React from 'react';
import { AiFillGithub } from 'react-icons/ai';
import { FaExternalLinkAlt } from 'react-icons/fa';
import RickAndMorthyImage from '../../assets/rick-and-morthy.png';


export const RickAandMorthyModal = () => {
    return (
        <div className="projects-project">
            <img    
                src={RickAndMorthyImage}
                alt="pic"
                className="_projects-project-img"
                />
            <div className="_card-efecto-hover-projects">
                <h1 className="h1-efecto-hover">Rick And Morthy App</h1>
                <div className="icons-externals-links">
                    <a
                        href="https://react-rick-and-morthy.vercel.app/" 
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaExternalLinkAlt color='white' />
                    </a>
                    <a
                        href="https://github.com/Elvis1327/React-RickAndMorthy" 
                        target="_blank"
                        rel="noreferrer"
                    >
                        <AiFillGithub color='white' fontSize='35px' />
                    </a>
                </div>
            </div>
        </div>
    )
}

