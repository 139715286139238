import React from 'react';

import ilustracion_about from '../../assets/ilustracion.svg';
import ilustration_interes from '../../assets/ilustration_interes.svg';

export const About = () => {
    return (
        <>
        <div className="about__me-info">
            <h4>ABOUT ME</h4>
            <h1>Let me introduce myself.</h1>
            <div className="info__ilustration">
                <img src={ilustracion_about} alt="pic"/>
                <div className="about-p-info">
                    <p>
                        I'm a  Junior Software Engineer pasionated about Technologies, I'm looking for new opportunities
                        in the Programing World, someone who can provide value in a team, someone who has determination,
                        someone who never Sleep until the job is finish.
                    </p>
                </div>
            </div>
        </div>

        <div className="interes__aficciones">
            <h1>Interests and hobbies</h1>
            <div className="interes__ilustration">
                <p>
                    I'm a very Disciplined person who takes everything serius, I love to be on time every single day.
                    For my hobbies I do not like to play video games, when i feel stressed I like to disconnect my mind and
                    go to the Gym, I like to be around of people who have different oppinions.
                </p>
                <img src={ilustration_interes} alt="pic" />
            </div>
        </div>
        </>
    )
}


