import React  from 'react';

import { RickAandMorthyModal } from './modals/RickAndMorthyModal';
import { StoreModal } from './modals/StoreModal';


export const Projects = () => {

    return (
        <div className="projets">
                <div className="projects-info">
                    <h4>Projects</h4>
                    <h1>Look some of my projects</h1>
                </div>
                <div className="projects-data">
                    <StoreModal />
                    <RickAandMorthyModal />
                </div>
        </div>
    )
}
