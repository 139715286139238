import React from 'react';

import webHabilities from '../../helpers/_ui-habilities';

export const Habilidades = () => {
    const { back_end, front_end } = webHabilities;
    return (
        <>
            <div className="contenedor__principal">
                <div className="habilities-name">
                    <h1>Skills</h1>
                </div>
            </div>
            <div className="habilities">
                {/* FRONT-END */}
                <div className="front-end">
                    <div className="front-end__name">
                        <h1>FRONT-END</h1>
                    </div>
                    <div className="contenedor__habilities-front">
                        {front_end.map(({lenguaje, img}: any) => (
                            <div className="habilities__front-end">
                                <img className="_habilities-images" src={img} alt="pic" />
                                <h5>{lenguaje}</h5>
                            </div>
                        ))};
                    </div>
                </div>
                {/* BACK-END */}
                <div className="back-end">
                    <div className="back-end__name">
                        <h1>BACK-END</h1>
                    </div>
                    <div className="contenedor__habilities-back">
                        {back_end.map(({lenguaje, img}: any) => (
                            <div className="habilities__back-end">
                                <img className="_habilities-images" src={img} alt="pic" />
                                <h5>{lenguaje}</h5>
                            </div>
                        ))};
                    </div>
                </div>
            </div>
    </>
    )
}

