import React from 'react';
import ReactDom from 'react-dom';
import { App } from './App';
import './styles/styles.scss';

const main = document.querySelector('#root');
ReactDom.render(<App />, main );



