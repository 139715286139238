import React, { useState } from 'react'

const NavbarResponsive = () => {
    const [ collapse, setCollapse ] = useState(false);
    const handleCollapse = () => {
        setCollapse(!collapse)
    };
    return (
        <>
            <div className="menu">
                <h1>Menu</h1>
                <button onClick={handleCollapse}>
                    <i className="fas fa-bars"></i>
                </button>
                    <ul className={collapse ? 'navbar-link active' : 'navbar-link'}>
                        <li className="navbar-links">
                            <i className="fas fa-address-card"></i>
                            <a href="#about" className="links-hijos" onClick={()=> setCollapse(false)}>About</a>
                        </li>
                        <li className="navbar-links">
                            <i className="fas fa-wallet"></i>
                            <a href="#habilities" className="links-hijos" onClick={()=> setCollapse(false)}>Habilidades</a>
                        </li>
                        <li className="navbar-links">
                            <i className="fas fa-project-diagram"></i>
                            <a href="#projects" className="links-hijos" onClick={()=> setCollapse(false)}>Projects</a>
                        </li>
                    </ul>
            </div>
        </>
    )
}

export default NavbarResponsive;
